import Cookies from 'js-cookie';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { useIsSessionValidLazyQuery } from './__generated__/typescript-operations';

export const useAuthenticate = (redirect?: string) => {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = React.useState(false);
  const [query, { data }] = useIsSessionValidLazyQuery({
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    const executeQuery = async () => {
      const token = Cookies.get('hailtrace-identity-admin') || '';

      if (!token && redirect) {
        navigate(redirect);
      }

      try {
        await query({
          variables: { token: token as string },
        });
      } catch (e) {
        console.log(e);
      }
    };

    executeQuery();
    const interval = setInterval(executeQuery, 15000);

    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    if (!data) return;
    if (!data.isSessionValid && redirect) {
      navigate(redirect);
    }

    setAuthenticated(data.isSessionValid!);
  }, [data]);

  return { authenticated, setAuthenticated };
};
