import {
  Box,
  Button,
  CircularProgress,
  Grid,
  GridItem,
  TextField,
  Typography,
} from '@hailtrace/catalyst-ui';
import Cookies from 'js-cookie';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import HtLogo from '../assets/images/ht-logo.png';
import image from '../assets/images/storm-bg.png';
import { useAuthenticate } from '../useAuthenticate';
import { getCookieDomain } from '../utils/getCookieDomain';
import {
  AuthenticationTypeEnum,
  useAuthenticateMutation,
} from '../__generated__/typescript-operations';

const LoginPage: React.FC = () => {
  const { authenticated, setAuthenticated } = useAuthenticate();
  const [email, setEmail] = React.useState<string>('josh111@hailtrace.com');
  const [password, setPassword] = React.useState<string>('');
  const cookieDomain = getCookieDomain();

  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const [login] = useAuthenticateMutation({
    onError: (err) => {
      return false;
    },
    onCompleted: (result) => {
      if (!result.authenticate || !result.authenticate.session) return;
      const { session } = result.authenticate;

      const { token } = session;

      setLoading(false);

      if (!token) {
        return;
      }

      setAuthenticated(true);

      localStorage.setItem('token', token || '');
      localStorage.removeItem('authId');

      Cookies.set('hailtrace-identity-admin', token || '', {
        domain: cookieDomain,
      });
    },
  });

  React.useEffect(() => {
    if (authenticated) {
      navigate('/');
    }
  }, [authenticated]);

  const handleLogin = (e: any) => {
    e.preventDefault();

    login({
      variables: {
        input: {
          email,
          password,
          type: AuthenticationTypeEnum.Basic,
        },
      },
    });
  };

  return (
    <Wrapper>
      <Grid spacing="2">
        <GridItem xs="3">
          <LeftHalf>
            <WelcomeBox>
              <p>Welcome to</p>
              <p>HailTrace One</p>
              <img width="284" height="96" alt="hailtrace" src={HtLogo} />
            </WelcomeBox>
            <LeftHalfFooter>
              <span>How to Login</span>
            </LeftHalfFooter>
          </LeftHalf>
        </GridItem>
        <GridItem xs="9">
          <RightHalf>
            <LoginFormWrapper>
              <p>Login to HailTrace One</p>
              {/* <LoginDivider src={dividerImg} alt="divider" /> */}
              <form onSubmit={handleLogin}>
                <Box>
                  <Box mt={2}>
                    <TextField
                      autoComplete="username"
                      autoFocus
                      placeholder="John Smith or john.smith@email.com"
                      fullWidth
                      // defaultValue="josh111@hailtrace.com"
                      labelText="Username or email"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                      }
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField
                      autoComplete="current-password"
                      placeholder="*******"
                      fullWidth
                      labelText="Password"
                      type="password"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPassword(e.target.value)
                      }
                    />
                  </Box>
                </Box>
                <Box mt={2}>
                  <LoginButton
                    disabled={loading}
                    startIcon={
                      loading ? (
                        <CircularProgress color="secondary" size="18px" />
                      ) : undefined
                    }
                    type="submit"
                    fullWidth
                  >
                    {loading ? 'Authenticating...' : 'Login'}
                  </LoginButton>
                </Box>
              </form>

              <Box mt={2} display="flex" justifyContent="center">
                <Typography variant="description2">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </Typography>
              </Box>
            </LoginFormWrapper>
          </RightHalf>
        </GridItem>
      </Grid>
    </Wrapper>
  );
};

const ErrorBox = styled.div`
  margin-top: 15px;
  background: ${({ theme }) => theme.palette.error.light};
  border-radius: 5px;
  padding: 14px 0 14px 30px;
  color: ${({ theme }) => theme.palette.error.contrastText};
`;

const LoginButton = styled(Button)``;

const LoginDivider = styled.img`
  margin-top: 26px;
  margin-bottom: 26px;
`;
const LoginFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 425px;

  & > :nth-child(1) {
    font-weight: 500;
    margin-bottom: 26px;
  }
`;

const RightHalf = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 10px;
  min-height: 100%;
  align-items: center;
  justify-content: center;
`;

const LeftHalfFooter = styled.div`
  position: absolute;
  bottom: 46px;
  left: 38px;
  font-size: 14px;
`;

const WelcomeBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  line-height: 190%;
  padding-top: 48px;

  & > :nth-child(2) {
    font-weight: 400;
    font-size: 22px;
  }

  & > :nth-child(1) {
    font-weight: 100;
    font-size: 14px;
  }

  & > img {
    margin-top: 47px;
  }
`;

const LeftHalf = styled.div`
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  justify-content: center;
  border-radius: 10px;
  min-height: 100%;
  background: url(${image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 80%;
`;

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  display: flex;
  padding: 10px;
  height: 100%;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.background.page};
`;

export default LoginPage;
