import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values.BigInt can represent values between -(2^53) + 1 and 2^53 - 1. */
  BigInt: any;
  /** Date custom scalar type */
  Date: any;
};

export enum ActorTypeEnum {
  User = 'USER',
  Company = 'COMPANY',
}

export type AddPlanToSubscriptionInput = {
  subscriptionId: Scalars['ID'];
  planId: Scalars['ID'];
  restrictions: SubscriptionRestrictionInput;
};

export type AddUserRoleInput = {
  roleIds: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type ArrayFilterInput = {
  filters: Array<FilterInput>;
};

export type AuthenticatedSession = {
  __typename?: 'AuthenticatedSession';
  token?: Maybe<Scalars['String']>;
  totp?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type AuthenticationInput = {
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  idToken?: Maybe<Scalars['String']>;
  type: AuthenticationTypeEnum;
  deviceId?: Maybe<Scalars['String']>;
};

export type AuthenticationMutationResponse = MutationResponse & {
  __typename?: 'AuthenticationMutationResponse';
  message?: Maybe<Scalars['String']>;
  session?: Maybe<AuthenticatedSession>;
};

export enum AuthenticationTypeEnum {
  Basic = 'BASIC',
  Google = 'GOOGLE',
}

export enum BillingIntervalEnum {
  Month = 'MONTH',
  Year = 'YEAR',
}

export type BillingPlan = {
  __typename?: 'BillingPlan';
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  interval?: Maybe<BillingIntervalEnum>;
  product?: Maybe<BillingProduct>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
};

export type BillingPlanMutationResponse = MutationResponse & {
  __typename?: 'BillingPlanMutationResponse';
  message?: Maybe<Scalars['String']>;
  plan?: Maybe<BillingPlan>;
};

export enum BillingProcessorEnum {
  Zoho = 'ZOHO',
}

export type BillingProduct = {
  __typename?: 'BillingProduct';
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  restrictionType?: Maybe<SubscriptionRestrictionTypeEnum>;
  description?: Maybe<Scalars['String']>;
  roleGroups?: Maybe<Array<RoleGroup>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  plans?: Maybe<Array<BillingPlan>>;
};

export type BillingProductMutationResponse = MutationResponse & {
  __typename?: 'BillingProductMutationResponse';
  message?: Maybe<Scalars['String']>;
  product?: Maybe<BillingProduct>;
};

export type BillingSubscription = {
  __typename?: 'BillingSubscription';
  _id: Scalars['ID'];
  interval?: Maybe<BillingIntervalEnum>;
  active?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['ID']>;
  processorType?: Maybe<BillingProcessorEnum>;
  processorSubscriptionId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  plans?: Maybe<Array<BillingPlan>>;
  restrictions?: Maybe<Array<SubscriptionRestriction>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['Date']>;
  amount?: Maybe<Scalars['Float']>;
  nextBillingAt?: Maybe<Scalars['Date']>;
};

export type BillingSubscriptionMutationResponse = MutationResponse & {
  __typename?: 'BillingSubscriptionMutationResponse';
  message?: Maybe<Scalars['String']>;
  subscription?: Maybe<BillingSubscription>;
};

export type Command = {
  __typename?: 'Command';
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
};

export type CommandMutationResponse = MutationResponse & {
  __typename?: 'CommandMutationResponse';
  message?: Maybe<Scalars['String']>;
  command?: Maybe<Command>;
};

export enum CommandTypeEnum {
  Internal = 'INTERNAL',
  Any = 'ANY',
}

export type CommandsPagination = PaginatedResponse & {
  __typename?: 'CommandsPagination';
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  results: Array<Command>;
};

export type Company = {
  __typename?: 'Company';
  _id: Scalars['ID'];
  actorType?: Maybe<Scalars['String']>;
  rootActorId?: Maybe<Scalars['String']>;
  accountOwner?: Maybe<User>;
  zohoAccountId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  logoId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  mailingLocation?: Maybe<Location>;
  phoneNumbers?: Maybe<Array<PhoneNumber>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
};

export type CompanyMutationResponse = MutationResponse & {
  __typename?: 'CompanyMutationResponse';
  message?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
};

export type CompanyUsersFilterInput = {
  companyId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
};

export enum ContactTypeEnum {
  Lead = 'LEAD',
  Client = 'CLIENT',
}

export type CreateCommandInput = {
  name: Scalars['String'];
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type CreateCompanyInput = {
  name: Scalars['String'];
  zohoAccountId?: Maybe<Scalars['String']>;
  mailingLocation?: Maybe<LocationInput>;
  phoneNumbers: Array<CreatePhoneNumberInput>;
  accountOwner: CreateUserInput;
};

export type CreateCredentialInput = {
  actorId: Scalars['ID'];
  type: CredentialTypeEnum;
  willExpire?: Maybe<Scalars['Boolean']>;
  expiresTimestamp?: Maybe<Scalars['Date']>;
  generatedKey?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  restrictions?: Maybe<Array<CredentialRestrictionInput>>;
  allowedApis?: Maybe<Array<Scalars['ID']>>;
};

export type CreateFieldInput = {
  placeHolder: Scalars['String'];
  label: Scalars['String'];
  type: FieldTypeEnum;
  entity: EntityEnum;
  key: Scalars['String'];
  options?: Maybe<Array<FieldOptionInput>>;
  required: Scalars['Boolean'];
  accessType: FieldAccessTypeEnum;
  companyId?: Maybe<Scalars['ID']>;
};

export type CreatePermissionInput = {
  name: Scalars['String'];
  key: Scalars['String'];
  type: PermissionTypeEnum;
  description?: Maybe<Scalars['String']>;
  commands?: Maybe<Array<Scalars['ID']>>;
};

export type CreatePhoneNumberInput = {
  type: Scalars['String'];
  label: Scalars['String'];
  country_code: Scalars['Int'];
  national_number: Scalars['String'];
  primary: Scalars['Boolean'];
};

export type CreatePlanInput = {
  name?: Maybe<Scalars['String']>;
  interval: BillingIntervalEnum;
  product: Scalars['ID'];
};

export type CreateProductInput = {
  name: Scalars['String'];
  restrictionType?: Maybe<SubscriptionRestrictionTypeEnum>;
  description?: Maybe<Scalars['String']>;
  roleGroups?: Maybe<Array<Scalars['ID']>>;
};

export type CreateRoleGroupInput = {
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['ID']>>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<RoleGroupTypeEnum>;
};

export type CreateRoleInput = {
  type?: Maybe<RoleTypeEnum>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Scalars['ID']>>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
};

export type CreateServiceApiInput = {
  name: Scalars['String'];
  endpoints?: Maybe<Array<Scalars['String']>>;
};

export type CreateUserInput = {
  companyId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  mailingLocation?: Maybe<LocationInput>;
  phoneNumbers?: Maybe<Array<CreatePhoneNumberInput>>;
  type?: Maybe<UserTypeEnum>;
};

export type Credential = {
  __typename?: 'Credential';
  _id: Scalars['ID'];
  type?: Maybe<CredentialTypeEnum>;
  actorId?: Maybe<Scalars['ID']>;
  willExpire?: Maybe<Scalars['Boolean']>;
  expiresTimestamp?: Maybe<Scalars['Date']>;
  active?: Maybe<Scalars['Boolean']>;
  generatedKey?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['ID']>;
  restrictions?: Maybe<Array<CredentialRestriction>>;
  allowedApis?: Maybe<Array<ServiceApi>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
};

export type CredentialMutationResponse = MutationResponse & {
  __typename?: 'CredentialMutationResponse';
  message?: Maybe<Scalars['String']>;
  credential?: Maybe<Credential>;
};

export type CredentialRestriction = {
  __typename?: 'CredentialRestriction';
  type?: Maybe<CredentialRestrictionTypeEnum>;
  values?: Maybe<Array<Scalars['String']>>;
};

export type CredentialRestrictionInput = {
  type: CredentialRestrictionTypeEnum;
  values?: Maybe<Array<Scalars['String']>>;
};

export enum CredentialRestrictionTypeEnum {
  HttpReferrer = 'HTTP_REFERRER',
  IpAddress = 'IP_ADDRESS',
}

export enum CredentialTypeEnum {
  Password = 'PASSWORD',
  Google = 'GOOGLE',
  Facebook = 'FACEBOOK',
  Linkedin = 'LINKEDIN',
  Twitter = 'TWITTER',
  ApiKey = 'API_KEY',
  RecoveryKey = 'RECOVERY_KEY',
}

export type CredentialsFilterInput = {
  companyId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
};

export type DateRangeFilterInput = {
  value: DateRangeInput;
};

export type DateRangeInput = {
  lte?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  gt?: Maybe<Scalars['Date']>;
};

export type DefaultPaginationInput = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
};

export type DeletePhoneNumberInput = {
  _id: Scalars['ID'];
};

export enum EntityEnum {
  User = 'USER',
  Company = 'COMPANY',
  Contact = 'CONTACT',
  Asset = 'ASSET',
  Opportunity = 'OPPORTUNITY',
}

export type Field = {
  __typename?: 'Field';
  _id: Scalars['ID'];
  type?: Maybe<FieldTypeEnum>;
  accessType?: Maybe<FieldAccessTypeEnum>;
  companyId?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  entity?: Maybe<EntityEnum>;
  relationshipEntity?: Maybe<EntityEnum>;
  key?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  isArray?: Maybe<Scalars['Boolean']>;
  adminOnly?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<FieldOption>>;
  deleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
};

export enum FieldAccessTypeEnum {
  System = 'SYSTEM',
  Custom = 'CUSTOM',
}

export type FieldMutationResponse = MutationResponse & {
  __typename?: 'FieldMutationResponse';
  message?: Maybe<Scalars['String']>;
  field?: Maybe<Field>;
};

export type FieldOption = {
  __typename?: 'FieldOption';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type FieldOptionInput = {
  label: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export enum FieldTypeEnum {
  Text = 'TEXT',
  Textarea = 'TEXTAREA',
  Image = 'IMAGE',
  Phone = 'PHONE',
  Point = 'POINT',
  Address = 'ADDRESS',
  Datetime = 'DATETIME',
  Date = 'DATE',
  Email = 'EMAIL',
  File = 'FILE',
  Select = 'SELECT',
  Checkbox = 'CHECKBOX',
  Relationship = 'RELATIONSHIP',
  Currency = 'CURRENCY',
}

export type FieldTypeResponse = {
  __typename?: 'FieldTypeResponse';
  fieldTypes?: Maybe<Array<Scalars['String']>>;
};

export type FilterCompanyInput = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filters?: Maybe<Array<FilterInput>>;
  searchTerm?: Maybe<Scalars['String']>;
};

export type FilterInput = {
  /** The name of the field to filter. Fields are defined by the `Field` graph. */
  fieldName?: Maybe<Scalars['String']>;
  /** 'text' requires 'fieldName' to be set */
  text?: Maybe<TextFilterInput>;
  /** 'dateRange' requires 'fieldName' to be set */
  dateRange?: Maybe<DateRangeFilterInput>;
  /** 'and' must be by itself, it cannot have a 'fieldName' */
  and?: Maybe<ArrayFilterInput>;
  /** 'or' must be by itself, it cannot have a fieldName */
  or?: Maybe<ArrayFilterInput>;
};

export type GeoJsonGeometry = {
  __typename?: 'GeoJSONGeometry';
  type?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Array<Maybe<Array<Maybe<Array<Scalars['Float']>>>>>>;
};

export type GetFieldsInput = {
  entityName?: Maybe<EntityEnum>;
  accessType?: Maybe<FieldAccessTypeEnum>;
  companyId?: Maybe<Scalars['ID']>;
};

export type Location = {
  __typename?: 'Location';
  houseNumber?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  geometry?: Maybe<Point>;
};

export type LocationInput = {
  houseNumber?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  geometry?: Maybe<PointInput>;
};

export enum MarkerTypeEnum {
  Airport = 'AIRPORT',
  Banking = 'BANKING',
  Beach = 'BEACH',
  CarDealer = 'CAR_DEALER',
  Email = 'EMAIL',
  Factory = 'FACTORY',
  Food = 'FOOD',
  Gas = 'GAS',
  Government = 'GOVERNMENT',
  Groceries = 'GROCERIES',
  Hotel = 'HOTEL',
  House = 'HOUSE',
  Info = 'INFO',
  Medical = 'MEDICAL',
  Park = 'PARK',
  Parking = 'PARKING',
  Person = 'PERSON',
  Phone = 'PHONE',
  Question = 'QUESTION',
  Star = 'STAR',
  Store = 'STORE',
  Warning = 'WARNING',
  Wifi = 'WIFI',
  World = 'WORLD',
  BlueA = 'BLUE_A',
  BlueArrow = 'BLUE_ARROW',
  BlueB = 'BLUE_B',
  BlueC = 'BLUE_C',
  BlueCheckBox = 'BLUE_CHECK_BOX',
  BlueConcave = 'BLUE_CONCAVE',
  BlueConvex = 'BLUE_CONVEX',
  BlueD = 'BLUE_D',
  BlueExclamationMark = 'BLUE_EXCLAMATION_MARK',
  BlueHollow = 'BLUE_HOLLOW',
  BlueMinus = 'BLUE_MINUS',
  BluePin = 'BLUE_PIN',
  BluePlus = 'BLUE_PLUS',
  BlueQuestionMark = 'BLUE_QUESTION_MARK',
  BlueStar = 'BLUE_STAR',
  BlueX = 'BLUE_X',
  GreenA = 'GREEN_A',
  GreenArrow = 'GREEN_ARROW',
  GreenB = 'GREEN_B',
  GreenC = 'GREEN_C',
  GreenCheckBox = 'GREEN_CHECK_BOX',
  GreenConcave = 'GREEN_CONCAVE',
  GreenConvex = 'GREEN_CONVEX',
  GreenD = 'GREEN_D',
  GreenExclamationMark = 'GREEN_EXCLAMATION_MARK',
  GreenHollow = 'GREEN_HOLLOW',
  GreenMinus = 'GREEN_MINUS',
  GreenPin = 'GREEN_PIN',
  GreenPlus = 'GREEN_PLUS',
  GreenQuestionMark = 'GREEN_QUESTION_MARK',
  GreenStar = 'GREEN_STAR',
  GreenX = 'GREEN_X',
  RedA = 'RED_A',
  RedArrow = 'RED_ARROW',
  RedB = 'RED_B',
  RedC = 'RED_C',
  RedCheckBox = 'RED_CHECK_BOX',
  RedConcave = 'RED_CONCAVE',
  RedConvex = 'RED_CONVEX',
  RedD = 'RED_D',
  RedExclamationMark = 'RED_EXCLAMATION_MARK',
  RedHollow = 'RED_HOLLOW',
  RedMinus = 'RED_MINUS',
  RedPin = 'RED_PIN',
  RedPlus = 'RED_PLUS',
  RedQuestionMark = 'RED_QUESTION_MARK',
  RedStar = 'RED_STAR',
  RedX = 'RED_X',
  OrangeArrow = 'ORANGE_ARROW',
  OrangePin = 'ORANGE_PIN',
}

export type Mutation = {
  __typename?: 'Mutation';
  authenticate: AuthenticationMutationResponse;
  requestPasswordReset?: Maybe<RequestPasswordResetReponse>;
  register: RegistrationMutationResponse;
  addPlanToSubscription: BillingSubscription;
  removePlanFromSubscription: BillingSubscription;
  createPlan: BillingPlanMutationResponse;
  updatePlan: BillingPlanMutationResponse;
  createProduct: BillingProductMutationResponse;
  updateProduct: BillingProductMutationResponse;
  deletePlan: BillingPlanMutationResponse;
  deleteProduct: BillingProductMutationResponse;
  createCompany?: Maybe<CompanyMutationResponse>;
  updateCompany?: Maybe<CompanyMutationResponse>;
  disableCompany?: Maybe<CompanyMutationResponse>;
  enableCompany?: Maybe<CompanyMutationResponse>;
  deleteCompany?: Maybe<CompanyMutationResponse>;
  restoreCompany?: Maybe<CompanyMutationResponse>;
  permanentlyDeleteCompany?: Maybe<CompanyMutationResponse>;
  createCredential: CredentialMutationResponse;
  updateCredential: CredentialMutationResponse;
  deleteCredential: CredentialMutationResponse;
  createField?: Maybe<FieldMutationResponse>;
  updateField?: Maybe<FieldMutationResponse>;
  deleteField?: Maybe<FieldMutationResponse>;
  createRole: RoleMutationResponse;
  updateRole: RoleMutationResponse;
  deleteRole: RoleMutationResponse;
  createCommand: CommandMutationResponse;
  updateCommand: CommandMutationResponse;
  deleteCommand: CommandMutationResponse;
  createRoleGroup: RoleGroupMutationResponse;
  updateRoleGroup: RoleGroupMutationResponse;
  deleteRoleGroup: RoleGroupMutationResponse;
  createPermission: PermissionMutationResponse;
  updatePermission: PermissionMutationResponse;
  deletePermission: PermissionMutationResponse;
  createServiceApi: ServiceApiMutationResponse;
  updateServiceApi: ServiceApiMutationResponse;
  deleteServiceApi: ServiceApiMutationResponse;
  addUserRole: UserMutationResponse;
  removeUserRole: UserMutationResponse;
  createUser?: Maybe<UserMutationResponse>;
  updateUser?: Maybe<UserMutationResponse>;
  disableUser?: Maybe<UserMutationResponse>;
  enableUser?: Maybe<UserMutationResponse>;
  deleteUser?: Maybe<UserMutationResponse>;
  restoreUser?: Maybe<UserMutationResponse>;
  permanentlyDeleteUser?: Maybe<UserMutationResponse>;
};

export type MutationAuthenticateArgs = {
  input: AuthenticationInput;
};

export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};

export type MutationRegisterArgs = {
  input: RegistrationInput;
};

export type MutationAddPlanToSubscriptionArgs = {
  input: AddPlanToSubscriptionInput;
};

export type MutationRemovePlanFromSubscriptionArgs = {
  input: RemovePlanFromSubscriptionInput;
};

export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
};

export type MutationUpdatePlanArgs = {
  input: UpdatePlanInput;
};

export type MutationCreateProductArgs = {
  input: CreateProductInput;
};

export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};

export type MutationDeletePlanArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteProductArgs = {
  id: Scalars['ID'];
};

export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};

export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};

export type MutationDisableCompanyArgs = {
  id: Scalars['ID'];
};

export type MutationEnableCompanyArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCompanyArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreCompanyArgs = {
  id: Scalars['ID'];
};

export type MutationPermanentlyDeleteCompanyArgs = {
  id: Scalars['ID'];
};

export type MutationCreateCredentialArgs = {
  input: CreateCredentialInput;
};

export type MutationUpdateCredentialArgs = {
  input: UpdateCredentialInput;
};

export type MutationDeleteCredentialArgs = {
  id: Scalars['ID'];
};

export type MutationCreateFieldArgs = {
  input: CreateFieldInput;
};

export type MutationUpdateFieldArgs = {
  input: UpdateFieldInput;
};

export type MutationDeleteFieldArgs = {
  id: Scalars['ID'];
};

export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};

export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};

export type MutationDeleteRoleArgs = {
  id: Scalars['ID'];
};

export type MutationCreateCommandArgs = {
  input: CreateCommandInput;
};

export type MutationUpdateCommandArgs = {
  input: UpdateCommandInput;
};

export type MutationDeleteCommandArgs = {
  id: Scalars['ID'];
};

export type MutationCreateRoleGroupArgs = {
  input: CreateRoleGroupInput;
};

export type MutationUpdateRoleGroupArgs = {
  input: UpdateRoleGroupInput;
};

export type MutationDeleteRoleGroupArgs = {
  id: Scalars['ID'];
};

export type MutationCreatePermissionArgs = {
  input: CreatePermissionInput;
};

export type MutationUpdatePermissionArgs = {
  input: UpdatePermissionInput;
};

export type MutationDeletePermissionArgs = {
  id: Scalars['ID'];
};

export type MutationCreateServiceApiArgs = {
  input: CreateServiceApiInput;
};

export type MutationUpdateServiceApiArgs = {
  input: UpdateServiceApiInput;
};

export type MutationDeleteServiceApiArgs = {
  id: Scalars['ID'];
};

export type MutationAddUserRoleArgs = {
  input: AddUserRoleInput;
};

export type MutationRemoveUserRoleArgs = {
  input: RemoveUserRoleInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationDisableUserArgs = {
  id: Scalars['ID'];
};

export type MutationEnableUserArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};

export type MutationRestoreUserArgs = {
  id: Scalars['ID'];
};

export type MutationPermanentlyDeleteUserArgs = {
  id: Scalars['ID'];
};

export type MutationResponse = {
  message?: Maybe<Scalars['String']>;
};

export type PaginatedCompanyResponse = PaginatedResponse & {
  __typename?: 'PaginatedCompanyResponse';
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Company>>;
};

export type PaginatedCredentialsResponse = PaginatedResponse & {
  __typename?: 'PaginatedCredentialsResponse';
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Credential>>;
};

export type PaginatedResponse = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedUsers = PaginatedResponse & {
  __typename?: 'PaginatedUsers';
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  results: Array<User>;
};

export type Permission = {
  __typename?: 'Permission';
  _id: Scalars['ID'];
  type?: Maybe<PermissionTypeEnum>;
  name?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  commands?: Maybe<Array<Command>>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['Date']>;
};

export type PermissionMutationResponse = MutationResponse & {
  __typename?: 'PermissionMutationResponse';
  message?: Maybe<Scalars['String']>;
  permission?: Maybe<Permission>;
};

export enum PermissionTypeEnum {
  Internal = 'INTERNAL',
  Any = 'ANY',
}

export type PermissionsPagination = PaginatedResponse & {
  __typename?: 'PermissionsPagination';
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  results: Array<Permission>;
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  _id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['Int']>;
  national_number?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
};

export type PhoneNumberInput = {
  create?: Maybe<Array<CreatePhoneNumberInput>>;
  update?: Maybe<Array<UpdatePhoneNumberInput>>;
  delete?: Maybe<Array<DeletePhoneNumberInput>>;
};

export enum PhoneNumberTypeEnum {
  Mobile = 'MOBILE',
  Landline = 'LANDLINE',
}

export type Point = {
  __typename?: 'Point';
  type: Scalars['String'];
  coordinates?: Maybe<Array<Scalars['Float']>>;
};

export type PointInput = {
  type: Scalars['String'];
  coordinates: Array<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  isSessionValid?: Maybe<Scalars['Boolean']>;
  subscriptions: Array<BillingSubscription>;
  subscription?: Maybe<BillingSubscription>;
  plans: Array<BillingPlan>;
  products: Array<BillingProduct>;
  product?: Maybe<BillingProduct>;
  company: Company;
  companies: PaginatedCompanyResponse;
  companyUsers: PaginatedUsers;
  credentials: PaginatedCredentialsResponse;
  getFieldTypes?: Maybe<FieldTypeResponse>;
  getFields?: Maybe<Array<Field>>;
  addressStates?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceApis: ServiceApiPagination;
  commands: CommandsPagination;
  permissions: PermissionsPagination;
  roles: RolesPagination;
  roleGroups: RoleGroupsPagination;
  restrictionGeometries: Array<RestrictionGeometry>;
  users: PaginatedUsers;
  user?: Maybe<User>;
};

export type QueryIsSessionValidArgs = {
  token: Scalars['String'];
};

export type QuerySubscriptionsArgs = {
  companyId: Scalars['ID'];
};

export type QuerySubscriptionArgs = {
  id: Scalars['ID'];
};

export type QueryProductArgs = {
  id: Scalars['ID'];
};

export type QueryCompanyArgs = {
  id: Scalars['ID'];
};

export type QueryCompaniesArgs = {
  input: FilterCompanyInput;
};

export type QueryCompanyUsersArgs = {
  input: CompanyUsersFilterInput;
};

export type QueryCredentialsArgs = {
  input: CredentialsFilterInput;
};

export type QueryGetFieldsArgs = {
  input?: Maybe<GetFieldsInput>;
};

export type QueryServiceApisArgs = {
  input: DefaultPaginationInput;
};

export type QueryCommandsArgs = {
  input: DefaultPaginationInput;
};

export type QueryPermissionsArgs = {
  input: DefaultPaginationInput;
};

export type QueryRolesArgs = {
  input: DefaultPaginationInput;
};

export type QueryRoleGroupsArgs = {
  input: DefaultPaginationInput;
};

export type QueryUsersArgs = {
  input: DefaultPaginationInput;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type RegionRestriction = {
  __typename?: 'RegionRestriction';
  type?: Maybe<RegionRestrictionTypeEnum>;
  name?: Maybe<Scalars['String']>;
};

export type RegionRestrictionInput = {
  _id: Scalars['ID'];
  type?: Maybe<RegionRestrictionTypeEnum>;
  name?: Maybe<Scalars['String']>;
};

export enum RegionRestrictionTypeEnum {
  State = 'STATE',
  City = 'CITY',
  Country = 'COUNTRY',
}

export type RegistrationInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  companyName: Scalars['String'];
};

export type RegistrationMutationResponse = MutationResponse & {
  __typename?: 'RegistrationMutationResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
};

export type RemovePlanFromSubscriptionInput = {
  subscriptionId: Scalars['ID'];
  planId: Scalars['ID'];
};

export type RemoveUserRoleInput = {
  roleIds: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type RequestPasswordResetInput = {
  email: Scalars['String'];
};

export type RequestPasswordResetReponse = MutationResponse & {
  __typename?: 'RequestPasswordResetReponse';
  message?: Maybe<Scalars['String']>;
};

export type RestrictionGeometry = {
  __typename?: 'RestrictionGeometry';
  _id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  geometry?: Maybe<GeoJsonGeometry>;
  properties?: Maybe<RestrictionGeometryProperties>;
};

export type RestrictionGeometryProperties = {
  __typename?: 'RestrictionGeometryProperties';
  type?: Maybe<RegionRestrictionTypeEnum>;
  name?: Maybe<Scalars['String']>;
};

export type Role = {
  __typename?: 'Role';
  _id: Scalars['ID'];
  type?: Maybe<RoleTypeEnum>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Permission>>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
};

export type RoleGroup = {
  __typename?: 'RoleGroup';
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<RoleGroupTypeEnum>;
  description?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Role>>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['Date']>;
};

export type RoleGroupMutationResponse = MutationResponse & {
  __typename?: 'RoleGroupMutationResponse';
  message?: Maybe<Scalars['String']>;
  roleGroup?: Maybe<RoleGroup>;
};

export enum RoleGroupTypeEnum {
  Internal = 'INTERNAL',
  Any = 'ANY',
}

export type RoleGroupsPagination = PaginatedResponse & {
  __typename?: 'RoleGroupsPagination';
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  results: Array<RoleGroup>;
};

export type RoleMutationResponse = MutationResponse & {
  __typename?: 'RoleMutationResponse';
  message?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
};

export enum RoleTypeEnum {
  Internal = 'INTERNAL',
  Any = 'ANY',
}

export type RolesPagination = PaginatedResponse & {
  __typename?: 'RolesPagination';
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  results: Array<Role>;
};

export type ServiceApi = {
  __typename?: 'ServiceAPI';
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  endpoints?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
};

export type ServiceApiMutationResponse = MutationResponse & {
  __typename?: 'ServiceAPIMutationResponse';
  message?: Maybe<Scalars['String']>;
  serviceApi?: Maybe<ServiceApi>;
};

export type ServiceApiPagination = PaginatedResponse & {
  __typename?: 'ServiceApiPagination';
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  results: Array<ServiceApi>;
};

export type SubscriptionPlanInput = {
  _id: Scalars['ID'];
};

export type SubscriptionRestriction = {
  __typename?: 'SubscriptionRestriction';
  productId: Scalars['ID'];
  type?: Maybe<SubscriptionRestrictionTypeEnum>;
  regions?: Maybe<Array<RegionRestriction>>;
  limit?: Maybe<Scalars['Int']>;
};

export type SubscriptionRestrictionInput = {
  productId: Scalars['ID'];
  type?: Maybe<SubscriptionRestrictionTypeEnum>;
  mapType?: Maybe<WeatherTypesEnum>;
  regions?: Maybe<Array<RegionRestrictionInput>>;
  limit?: Maybe<Scalars['Int']>;
};

export enum SubscriptionRestrictionTypeEnum {
  Seats = 'SEATS',
  Map = 'MAP',
  None = 'NONE',
}

export type TextFilterInput = {
  value: Scalars['String'];
};

export type UpdateCommandInput = {
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type UpdateCompanyInput = {
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  zohoAccountId?: Maybe<Scalars['String']>;
  mailingLocation?: Maybe<LocationInput>;
  phoneNumbers?: Maybe<PhoneNumberInput>;
};

export type UpdateCredentialInput = {
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  restrictions?: Maybe<Array<CredentialRestrictionInput>>;
  allowedApis?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateFieldInput = {
  _id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<Maybe<FieldOptionInput>>>;
};

export type UpdatePermissionInput = {
  _id: Scalars['ID'];
  type?: Maybe<PermissionTypeEnum>;
  name?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  commands?: Maybe<Array<Scalars['ID']>>;
};

export type UpdatePhoneNumberInput = {
  _id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type UpdatePlanInput = {
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  interval?: Maybe<BillingIntervalEnum>;
  product?: Maybe<Scalars['ID']>;
};

export type UpdateProductInput = {
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  restrictionType?: Maybe<SubscriptionRestrictionTypeEnum>;
  description?: Maybe<Scalars['String']>;
  roleGroups?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateRoleGroupInput = {
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['ID']>>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<RoleGroupTypeEnum>;
};

export type UpdateRoleInput = {
  _id: Scalars['ID'];
  type?: Maybe<RoleTypeEnum>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateServiceApiInput = {
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  endpoints?: Maybe<Array<Scalars['String']>>;
};

export type UpdateSubscriptionInput = {
  _id: Scalars['ID'];
  plans?: Maybe<Array<SubscriptionPlanInput>>;
  restrictions?: Maybe<Array<SubscriptionRestrictionInput>>;
};

export type UpdateUserInput = {
  _id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mailingLocation?: Maybe<LocationInput>;
  phoneNumbers?: Maybe<PhoneNumberInput>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  actorType?: Maybe<ActorTypeEnum>;
  company?: Maybe<Company>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<UserTypeEnum>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  mailingLocation?: Maybe<Location>;
  phoneNumbers?: Maybe<Array<PhoneNumber>>;
  roles?: Maybe<Array<Role>>;
};

export type UserMutationResponse = MutationResponse & {
  __typename?: 'UserMutationResponse';
  message?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export enum UserTypeEnum {
  Admin = 'ADMIN',
  Customer = 'CUSTOMER',
}

export enum VerificationTypeEnum {
  Email = 'EMAIL',
  MobileNumber = 'MOBILE_NUMBER',
}

export enum WeatherTypesEnum {
  MeteorologistWindSpeed = 'METEOROLOGIST_WIND_SPEED',
  MeteorologistTornado = 'METEOROLOGIST_TORNADO',
  MeteorologistHailSize = 'METEOROLOGIST_HAIL_SIZE',
  AlgorithmHailSize = 'ALGORITHM_HAIL_SIZE',
  AlgorithmDamageProbability = 'ALGORITHM_DAMAGE_PROBABILITY',
}

export type IsSessionValidQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type IsSessionValidQuery = { __typename?: 'Query' } & Pick<
  Query,
  'isSessionValid'
>;

export type RequestPasswordResetMutationVariables = Exact<{
  input: RequestPasswordResetInput;
}>;

export type RequestPasswordResetMutation = { __typename?: 'Mutation' } & {
  requestPasswordReset?: Maybe<
    { __typename?: 'RequestPasswordResetReponse' } & Pick<
      RequestPasswordResetReponse,
      'message'
    >
  >;
};

export type AuthenticateMutationVariables = Exact<{
  input: AuthenticationInput;
}>;

export type AuthenticateMutation = { __typename?: 'Mutation' } & {
  authenticate: { __typename?: 'AuthenticationMutationResponse' } & Pick<
    AuthenticationMutationResponse,
    'message'
  > & {
      session?: Maybe<
        { __typename?: 'AuthenticatedSession' } & Pick<
          AuthenticatedSession,
          'token' | 'totp'
        > & {
            user?: Maybe<{ __typename?: 'User' } & Pick<User, '_id' | 'email'>>;
          }
      >;
    };
};

export const IsSessionValidDocument = gql`
  query isSessionValid($token: String!) {
    isSessionValid(token: $token)
  }
`;

/**
 * __useIsSessionValidQuery__
 *
 * To run a query within a React component, call `useIsSessionValidQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsSessionValidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsSessionValidQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useIsSessionValidQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsSessionValidQuery,
    IsSessionValidQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsSessionValidQuery, IsSessionValidQueryVariables>(
    IsSessionValidDocument,
    options
  );
}
export function useIsSessionValidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsSessionValidQuery,
    IsSessionValidQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsSessionValidQuery, IsSessionValidQueryVariables>(
    IsSessionValidDocument,
    options
  );
}
export type IsSessionValidQueryHookResult = ReturnType<
  typeof useIsSessionValidQuery
>;
export type IsSessionValidLazyQueryHookResult = ReturnType<
  typeof useIsSessionValidLazyQuery
>;
export type IsSessionValidQueryResult = Apollo.QueryResult<
  IsSessionValidQuery,
  IsSessionValidQueryVariables
>;
export const RequestPasswordResetDocument = gql`
  mutation RequestPasswordReset($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input) {
      message
    }
  }
`;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >(RequestPasswordResetDocument, options);
}
export type RequestPasswordResetMutationHookResult = ReturnType<
  typeof useRequestPasswordResetMutation
>;
export type RequestPasswordResetMutationResult = Apollo.MutationResult<
  RequestPasswordResetMutation
>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;
export const AuthenticateDocument = gql`
  mutation Authenticate($input: AuthenticationInput!) {
    authenticate(input: $input) {
      session {
        token
        totp
        user {
          _id
          email
        }
      }
      message
    }
  }
`;
export type AuthenticateMutationFn = Apollo.MutationFunction<
  AuthenticateMutation,
  AuthenticateMutationVariables
>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthenticateMutation,
    AuthenticateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthenticateMutation,
    AuthenticateMutationVariables
  >(AuthenticateDocument, options);
}
export type AuthenticateMutationHookResult = ReturnType<
  typeof useAuthenticateMutation
>;
export type AuthenticateMutationResult = Apollo.MutationResult<
  AuthenticateMutation
>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<
  AuthenticateMutation,
  AuthenticateMutationVariables
>;
