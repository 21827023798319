// @ts-nocheck
import {
  ApolloLink,
  FetchResult,
  Observable,
  Operation,
} from '@apollo/client/core';
import { GraphQLError, print } from 'graphql';
import { Client, ClientOptions, createClient } from 'graphql-ws';
import { getToken, sessionId } from './apolloClient';

export default class WebSocketLink extends ApolloLink {
  restartRequested: boolean = false;

  private client: Client;

  restart: () => void;

  constructor(options: ClientOptions) {
    super();

    this.restart = () => {
      this.restartRequested = true;
    };

    this.client = createClient({
      ...options,
      connectionParams: () => ({
        sessionId,
        authorization: getToken(),
      }),
      on: {
        ...options.on,
        connected: (socket: any) => {
          options.on?.connected?.(socket);

          this.restart = () => {
            if (socket.readyState === WebSocket.OPEN) {
              socket.close(4205, 'Client Restart');
            } else {
              this.restartRequested = true;
            }
          };

          if (this.restartRequested) {
            this.restartRequested = false;
            this.restart();
          }
        },
      },
    });
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (err instanceof Error) {
              return sink.error(err);
            }

            if (err instanceof CloseEvent) {
              return sink.error(
                // reason will be available on clean closes
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ''}`
                )
              );
            }

            return sink.error(
              new Error(
                (err as GraphQLError[]).map(({ message }) => message).join(', ')
              )
            );
          },
        }
      );
    });
  }
}
