import {
  Box,
  Button,
  CircularProgress,
  Grid,
  GridItem,
  TextField,
  Toast,
} from '@hailtrace/catalyst-ui';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import HtLogo from '../assets/images/ht-logo.png';
import image from '../assets/images/storm-bg.png';
import { useRequestPasswordResetMutation } from '../__generated__/typescript-operations';

const ForgotPasswordPage: React.FC = () => {
  const [username, setUsername] = React.useState<string>('');

  const [error, setError] = React.useState<boolean | string>(false);

  const [loading, setLoading] = React.useState(false);

  const [requestReset] = useRequestPasswordResetMutation();
  const navigate = useNavigate();

  const handleReset = async () => {
    setLoading(true);
    setError(false);
    try {
      await requestReset({ variables: { input: { email: username } } });
      Toast({ type: 'success', message: 'Request sent! Check your email.' });
      navigate('/');
    } catch (ex: any) {
      setError(ex.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Grid spacing="2">
        <GridItem xs="3">
          <LeftHalf>
            <WelcomeBox>
              <p>Welcome to</p>
              <p>HailTrace One</p>
              <img width="284" height="96" alt="hailtrace" src={HtLogo} />
            </WelcomeBox>
            <LeftHalfFooter>
              <Link to="/">Login</Link>
            </LeftHalfFooter>
          </LeftHalf>
        </GridItem>
        <GridItem xs="9">
          <RightHalf>
            <LoginFormWrapper>
              <p>Request Password Reset</p>
              <form>
                <FormContainer>
                  <TextField
                    // errorText={!!error}
                    autoFocus
                    placeholder="john.smith@email.com"
                    fullWidth
                    labelText="Email"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setUsername(e.target.value)
                    }
                  />
                </FormContainer>
              </form>
              <Box mt={2}>
                <ResetButton
                  disabled={loading}
                  startIcon={
                    loading ? (
                      <CircularProgress color="secondary" size="18px" />
                    ) : undefined
                  }
                  onClick={handleReset}
                  fullWidth
                >
                  {loading ? 'Requesting...' : 'Reset Password'}
                </ResetButton>
                {error && <ErrorBox>{error}</ErrorBox>}
              </Box>
            </LoginFormWrapper>
          </RightHalf>
        </GridItem>
      </Grid>
    </Wrapper>
  );
};

const ErrorBox = styled.div`
  margin-top: 15px;
  background: ${({ theme }) => theme.palette.error.main};
  border-radius: 5px;
  padding: 14px 0 14px 30px;
  color: ${({ theme }) => theme.palette.error.contrastText};
`;

const ResetButton = styled(Button)``;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > ${TextField} {
    margin-bottom: 24px;
  }
`;

const LoginDivider = styled.img`
  margin-top: 26px;
  margin-bottom: 26px;
`;

const LoginFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 425px;

  & > :nth-child(1) {
    font-weight: 500;
    margin-bottom: 26px;
  }
`;

const RightHalf = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 10px;
  min-height: 100%;
  align-items: center;
  justify-content: center;
`;

const LeftHalfFooter = styled.div`
  position: absolute;
  bottom: 46px;
  left: 38px;
  font-size: 14px;
`;

const WelcomeBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  line-height: 190%;
  padding-top: 48px;

  & > :nth-child(2) {
    font-weight: 400;
    font-size: 22px;
  }

  & > :nth-child(1) {
    font-weight: 100;
    font-size: 14px;
  }

  & > img {
    margin-top: 47px;
  }
`;

const LeftHalf = styled.div`
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  justify-content: center;
  border-radius: 10px;
  min-height: 100%;
  background: url(${image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 80%;
`;

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  display: flex;
  padding: 10px;
  height: 100%;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.background.page};
`;

export default ForgotPasswordPage;
