import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  makeVar,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { Toast } from '@hailtrace/catalyst-ui';
import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';
import { w3cwebsocket } from 'websocket';
import cleanTypenameLink from './cleanTypename';
import WebSocketLink from './WebsocketLink';

export const sessionId = uuid();
export const sessionIdVar = makeVar(sessionId);

export function getToken() {
  return `Bearer ${Cookies.get('hailtrace-identity-admin')}`;
}

const getUrl = (type: 'ws' | 'http'): string => {
  const environment =
    process.env.REACT_APP_ENVIRONMENT || process.env.ENVIRONMENT;

  const getProtocol = () => {
    const secure =
      environment === 'production' ||
      environment === 'staging' ||
      environment === 'remote';

    return secure ? `${type}s` : type;
  };

  const protocol = getProtocol();
  switch (type) {
    case 'ws': {
      if (environment === 'production') {
        return `${protocol}://graphql.hailtrace.com/subscriptions`;
      }
      if (environment === 'staging') {
        return `${protocol}://graphql.hailstalker.com/subscriptions`;
      }
      if (environment === 'remote') {
        return 'ws://localhost:4000/subscriptions';
      }

      return 'ws://localhost:4000/subscriptions';
    }
    case 'http': {
      if (environment === 'production') {
        return `${protocol}://graphql.hailtrace.com/graphql`;
      }
      if (environment === 'staging') {
        return `${protocol}://graphql.hailstalker.com/graphql`;
      }
      if (environment === 'remote') {
        return 'http://localhost:4000/graphql';
      }
      return 'http://localhost:4000/graphql';
    }
    default: {
      throw new Error("Could not generate Apollo Client url's");
    }
  }
};

const cache = new InMemoryCache({});

const httpLink = new HttpLink({
  uri: getUrl('http'), // Server URL (must be absolute)
  fetch,
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: any) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${getToken()}`,
      sessionid: sessionId,
    },
  }));
  return forward(operation);
});

const errorLink = onError((ex) => {
  const { graphQLErrors } = ex;

  if (!graphQLErrors || !graphQLErrors.length) {
    // console.log(ex);
    return;
  }

  const error = graphQLErrors[0];
  // console.log(ex);

  Toast({ type: 'error', message: error.message });
});

const wsLink = new WebSocketLink({
  url: getUrl('ws'),
  webSocketImpl: w3cwebsocket,
  lazy: false,
  keepAlive: 10000,
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

export function reconnectWebsockets(): any {
  if (!wsLink) return;

  wsLink.restart();
}

export default function createApolloClient(initialState: any, _ctx: any) {
  return new ApolloClient({
    ssrMode: false,
    // typeDefs,
    link: ApolloLink.from([
      errorLink,
      cleanTypenameLink as any,
      authLink,
      splitLink,
    ]),
    cache: cache.restore(initialState),
  });
}
