import { Typography } from '@hailtrace/catalyst-ui';
import * as React from 'react';
import styled from 'styled-components';

interface PageTitleProps {
  title: string;
}

export const PageTitle: React.FC<PageTitleProps> = (props) => {
  return (
    <Wrapper {...props}>
      <Typography variant="h2">{props.title}</Typography>
      {props.children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  height: 44px;
`;
