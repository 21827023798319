import { CssReset, ToastWrapper } from '@hailtrace/catalyst-ui';
import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import Layout from './components/Shared/Layout';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import LoginPage from './pages/LoginPage';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
  };

  #root {
    min-height: 100%;
  };
`;

const App: React.FC = () => {
  return (
    <>
      <CssReset />
      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/" element={<Layout />} />
        </Routes>
        <ToastWrapper />
      </BrowserRouter>
    </>
  );
};

export default App;
